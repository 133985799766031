/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

/*body {
  background: #262626 !important;
}*/
/*div {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 90%;
    height: auto;
    box-shadow: 0 10px 20px black;
    color:white;
    font-family: sans-serif;
    transition: all .5s ease;
}*/
/*.video {*/
/*  width: 100px;*/
/*}*/
.d-inline{
  font-size: .8em;
}
.canvas {
  background: transparent;
  margin: 0;
  padding: 0;
  color:white;
  transition: all .5s ease;
}
.name {
  background: grey;
  width: 100px;
  height: 50px;
  padding: 10px;
  font-weight: bolder;
  font-family: sans-serif;
}
.category {
  line-height: .01;
  color: #3a3a3a;
  font-weight: bold;
}
.range {
  line-height: .01;
  color: white;
  font-weight: bold;
}
p {
  display: inline;
  padding: 0;
}
.list {
  background: #3a3a3a;
  overflow: auto;
  margin: 0;
  padding: 1rem 1rem 0 1rem;
  width: 100%;
  height: 500px;
  /*align-items: middle;*/
}
.list-color{
  color: #00edf9 !important;
  height: 5px !important;
}

a {
  color: white !important;
  font-family: sans-serif !important;
  /*text-decoration: none !important;*/
}
p a img {
  vertical-align: middle;
  /*height:8rem;*/
  max-height:12.3rem;
  width: 12.2em;
  transition: .5s ease;
  filter: brightness(80%);
}
/*p a img:hover {
  width:110px;
  box-shadow: 0 0 20px black;
  filter: brightness(120%);
}*/
p a iframe {
  vertical-align: middle;
  transition: .5s ease;
  filter: brightness(80%);
}
p a iframe:hover {
  width:110px;
  box-shadow: 0 0 20px black;
  filter: brightness(120%);
}
/*span {
  background: url(title.ico);
  width: 100px;
  height: 100px;
}*/

.video-title {
  display: block;
  padding: 10px;
}
.video-title:hover {
  background: grey;
}

@media only screen and (min-width: 1100px) { 

  section.list span > div > div.d-inline.col-lg-4.col > p > a > img {
    max-height: 12.3rem;
    width: 15.2em;
  }

  section.list span > div > div:nth-child(2){
    margin-left: 4rem;
  }
}